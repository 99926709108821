<template>
	<ion-page>
		<ion-header>
			<ion-toolbar color="branding">
				<ion-title> {{ typeName }} Subtypes </ion-title>
			</ion-toolbar>
		</ion-header>

		<ion-content>
			<ion-list>
				<ion-item
					v-for="subType in subTypes"
					:key="subType.id"
					lines="full"
					:style="{
						'--background': hasSubType(subType) ? '#def' : null,
					}"
				>
					<ion-checkbox
						slot="start"
						style="margin-right: 20px"
						:checked="hasSubType(subType)"
						@ionChange="clickSubType($event, subType)"
					></ion-checkbox>
					<ion-label
						style="
							display: flex;
							align-items: center;
							justify-content: space-between;
						"
					>
						<span style="overflow: hidden; text-overflow: ellipsis">
							{{ subType.name }}
						</span>

						<!-- FIXME: Don't do this! -->
						<!-- eslint-disable vue/no-mutating-props -->
						<select
							v-model="observationValuesById[subType.id]"
							style="z-index: 10; padding: 6px"
							v-if="hasSubType(subType)"
							:required="
								observationScalesById[subType.scaleId]
									.valueRequired
							"
						>
							<!-- eslint-enable vue/no-mutating-props -->
							<option value="" hidden>Severity</option>
							<option
								v-if="
									!observationScalesById[subType.scaleId]
										.valueRequired
								"
							></option>
							<option
								v-for="option in getScaleOptions(
									subType.scaleId,
								)"
								:key="option"
								:value="option"
							>
								{{
									formatScaleValue(
										option,
										observationScalesById[subType.scaleId],
									)
								}}
							</option>
						</select>
					</ion-label>
				</ion-item>
			</ion-list>
		</ion-content>

		<ion-footer>
			<ion-toolbar>
				<ion-button slot="start" color="danger" @click="removeType">
					Remove All
				</ion-button>
				<ion-button
					slot="end"
					color="primary"
					type="submit"
					@click="dismissModal"
				>
					Done
				</ion-button>
			</ion-toolbar>
		</ion-footer>
	</ion-page>
</template>

<style>
	.select-note-sub-type {
		background: rgba(0, 0, 0, 0.5) !important;
		/*padding-top: 80% !important;*/
	}
</style>

<script>
	import {
		IonTitle,
		IonButton,
		IonToolbar,
		IonHeader,
		IonLabel,
		IonItem,
		IonList,
		IonContent,
		IonPage,
		IonCheckbox,
		IonFooter,
		modalController,
	} from "@ionic/vue";
	import { defineAsyncComponent } from "vue";

	export default {
		name: "SelectNoteSubType",
		components: {
			IonTitle,
			IonButton,
			IonToolbar,
			IonHeader,
			IonLabel,
			IonItem,
			IonList,
			IonContent,
			IonPage,
			IonCheckbox,
			IonFooter,
		},
		props: {
			//TODO: use onWillDismiss and get rid of parent
			parent: { type: Object },
			subTypes: { type: Array },
			typeId: { type: String },
			observationValuesById: { type: Object },
		},
		data() {
			return {
				// Selected
			};
		},
		methods: {
			clickSubType(event, subType) {
				const hasSubType = this.hasSubType(subType);
				if (event.target.checked && !hasSubType)
					return this.parent.addObservationSubType(subType);
				if (!event.target.checked && hasSubType)
					return this.parent.removeObservationSubType(subType);
			},
			hasSubType(subType) {
				if (!this.parent.observations) return false;

				return this.parent.observations.find(
					(o) => o.observationSubTypeId == subType.id,
				);
			},
			dismissModal() {
				modalController.dismiss();
			},
			removeType() {
				this.parent.removeAllOfType(this.typeId);
			},
			getScaleOptions: function (scaleId) {
				var scale = this.observationScalesById[scaleId];

				var result = [];

				for (var i = scale.minValue; i <= scale.maxValue; i++)
					result.push(i);

				return result;
			},
			formatScaleValue: function (value, scale) {
				if (scale.percentScale)
					return `${(value / scale.maxValue) * 100}%`;

				return value;
			},
		},
		computed: {
			typeName() {
				return this.parent.observationTypesById[+this.typeId].name;
			},
			observationScalesById() {
				return this.parent.observationScalesById;
			},
		},
	};

	export function showSelectNoteSubType(parent, typeId, subTypes) {
		modalController
			.create({
				component: defineAsyncComponent(
					() => import("@/views/Modal/SelectNoteSubType.vue"),
				),
				componentProps: {
					parent: parent,
					subTypes: subTypes,
					typeId: typeId,
					observationValuesById: parent.observationValuesById,
				},
				cssClass: "select-note-sub-type",
			})
			.then((m) => m.present());
	}
</script>
