<template>
	<ion-page>
		<ion-header>
			<ion-toolbar :color="online ? 'branding' : 'dark'">
				<ion-title slot="start"
					>{{ !editing ? "Add" : "Edit" }} Note</ion-title
				>

				<ion-button
					@click="dismiss"
					slot="end"
					color="transparent"
					style="margin-right: 8px; overflow: hidden"
				>
					Cancel
				</ion-button>
			</ion-toolbar>
		</ion-header>

		<ion-content class="ion-padding">
			<!-- Notes property list -->
			<ion-list lines="full" style="padding: 0; margin-bottom: 0">
				<!-- Lot selection -->
				<ion-item
					button
					:detail="!plantings.length"
					@click="selectNotePlantings"
				>
					<ion-icon :icon="icons.map" slot="start"></ion-icon>

					<ion-label>
						<span v-if="plantings.length">
							{{
								plantings
									.map((p) => p.plantingRegion)
									.join(", ")
							}}
						</span>
						<span v-else
							>Select
							{{ user?.plantingRegionDisplayTextPlural }}</span
						>
					</ion-label>

					<ion-button
						v-if="plantings.length"
						@click="
							infoCollapsed = !infoCollapsed;
							$event.stopPropagation();
						"
						color="primary"
						slot="end"
						class="info-button"
					>
						<ion-icon
							:icon="icons.informationCircleOutline"
							style="margin-right: 3px"
						></ion-icon>
						Info
					</ion-button>
				</ion-item>

				<ion-badge
					class="subtype-badge"
					style="right: auto; left: 32px"
					color="danger"
					v-if="plantings.length > 1"
				>
					{{ plantings.length }}
				</ion-badge>
			</ion-list>

			<!-- Planting info dropdown -->
			<div
				class="planting-info-dropdown collapsable-panel multi-planting-info-panel"
				:class="infoExpandedClass"
				v-if="plantings.length"
			>
				<ion-grid
					v-for="planting in plantings"
					:key="planting.id"
					class="planting-info-table"
					:style="{ width: plantings.length === 1 ? '100%' : '80%' }"
				>
					<ion-row v-if="plantings.length > 1">
						<ion-col
							style="
								font-weight: bold;
								border-bottom: solid 1px #999;
							"
						>
							{{ planting.plantingRegion }}
						</ion-col>
					</ion-row>
					<ion-row>
						<ion-col> Commodity: </ion-col>
						<ion-col>
							{{ planting.displayCrop }}
						</ion-col>
					</ion-row>
					<ion-row>
						<ion-col> Acres: </ion-col>
						<ion-col> {{ planting.displayAcres }} ac </ion-col>
					</ion-row>
					<ion-row>
						<ion-col> Bed Count: </ion-col>
						<ion-col>
							{{ planting.currentBedCount }}
						</ion-col>
					</ion-row>
					<ion-row>
						<ion-col> Wet Date: </ion-col>
						<ion-col>
							{{ dateString(planting.wetDate) }}
						</ion-col>
					</ion-row>
					<ion-row>
						<ion-col> Harvest Date: </ion-col>
						<ion-col>
							{{ dateString(planting.currentHarvestDateRaw) }}
						</ion-col>
					</ion-row>
				</ion-grid>
			</div>

			<!-- Observations -->
			<div
				v-for="(
					subGroupedObservationSubTypes, categoryId
				) in groupedObservationSubTypes"
				:key="categoryId"
			>
				<h5>{{ observationCategoriesById[categoryId] }}</h5>

				<div style="overflow-x: auto; white-space: nowrap">
					<span
						class="icon-box"
						v-for="(
							subTypes, typeId
						) in subGroupedObservationSubTypes"
						:key="typeId"
						@click="selectObservationType(typeId, subTypes)"
					>
						<i :class="observationTypesById[typeId].icon"></i>

						<span
							style="
								text-overflow: ellipsis;
								display: block;
								overflow: hidden;
								max-width: 100%;
								padding: 0 8px;
							"
						>
							{{ observationTypesById[typeId].name }}
						</span>

						<ion-badge
							class="subtype-badge"
							color="danger"
							v-if="!!selectedSubTypesByTypeId[typeId]"
						>
							{{
								selectedSubTypesByTypeId[typeId] &&
								selectedSubTypesByTypeId[typeId].length
							}}
						</ion-badge>
					</span>
				</div>
			</div>

			<!-- TODO: Ability to add/remove photos when editing -->
			<template v-if="!editing">
				<h5>Photos</h5>

				<input
					id="notePhotoPicker"
					type="file"
					accept="image/*"
					@change="imageCaptured($event)"
					hidden
				/>

				<div
					id="photosWrapper"
					style="overflow-x: auto; white-space: nowrap"
				>
					<span
						class="icon-box"
						v-for="(image, i) in images"
						:key="i"
						@click="viewImage(i)"
						style="background-size: cover"
						:style="{ backgroundImage: `url(${image.imageData})` }"
					>
						<i class="fas fa-image" style="color: transparent"></i>
					</span>

					<span
						class="icon-box"
						style="background: #387ef5; color: #eee"
						onclick="document.getElementById('notePhotoPicker').click();"
					>
						<i class="fa fa-camera"></i>
					</span>
				</div>
			</template>

			<!-- Note Location -->
			<h5>Location</h5>
			<div
				id="locationWrapper"
				style="overflow-x: auto; white-space: nowrap"
			>
				<span
					class="icon-box"
					style="background: #387ef5; color: #eee"
					@click="editNoteLocation()"
				>
					<i class="fa fa-map-marked-alt"></i>
				</span>
				<span
					class="icon-box"
					style="background: #a00; color: #eee"
					v-if="noteLocation"
					@click="removeNoteLocation()"
				>
					<i class="fa fa-times"></i>
				</span>
			</div>

			<!--Note Text-->
			<label>
				<h5>Description</h5>

				<ion-textarea
					:value="description"
					@ionChange="description = $event.detail.value"
					auto-grow="true"
					style="
						background: #eee;
						margin-bottom: 16px;
						min-height: 59px;
					"
				></ion-textarea>
			</label>

			<!-- Note Urgency -->
			<template
				v-if="
					noteUrgencyTypesById &&
					Object.keys(noteUrgencyTypesById).length
				"
			>
				<!-- Show a toggle select if there are only two urgency options -->
				<ion-item
					v-if="Object.keys(noteUrgencyTypesById).length == 2"
					class="urgency-select-item"
				>
					<ion-label class="item-label">
						{{ highestUrgencyType.name }}
						<i class="fa" :class="highestUrgencyType.icon"></i>
					</ion-label>

					<ion-toggle
						:checked="urgencyToggleChecked"
						@ionChange="
							updateUrgencyTypeBool($event.target.checked)
						"
					></ion-toggle>
					<!--@ionChange="" value="" v-bind:checked=""></ion-toggle>-->
				</ion-item>
				<!-- Otherwise, use a select -->
				<ion-item v-else class="urgency-select-item">
					<ion-label>Urgency</ion-label>

					<ion-select
						placeholder="No Urgency Set"
						:value="noteUrgencyId"
						@ionChange="updateUrgencyType"
					>
						<ion-select-option
							v-for="urgency in noteUrgencyTypesById"
							:key="urgency.id"
							:value="urgency.id"
						>
							{{ urgency.name }}
						</ion-select-option>
					</ion-select>
				</ion-item>
			</template>

			<!-- Submit/Update Button -->
			<ion-button
				@click="
					submit(
						Array.from(addedPlantings),
						category,
						shareLocation,
						useNewLocation,
						description,
						images,
						addedObservations,
						removedObservations,
						observations,
						observationValuesById,
						observationSubTypesById,
						noteLocation,
						noteUrgencyId,
					)
				"
				:disabled="
					!plantings.length ||
					(!observations.length &&
						!images.length &&
						!description.length)
				"
				expand="block"
			>
				{{ !editing ? "Submit" : "Update" }}
			</ion-button>
		</ion-content>
	</ion-page>
</template>

<style>
	.image-box {
		width: 40px !important;
		height: 40px !important;
	}

	.image-box > i {
		display: contents;
	}

	.add-image-button {
		color: #444;
		float: right;
	}

	.sub-label {
		margin-bottom: 12px;
		margin-top: 2px;
		font-size: 15px;
	}

	.delete-image {
		display: none;
		background-color: white;
		transition: 0.3s;
		border-radius: 2px;
		padding: 4px 12px;
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
		position: absolute;
		font-weight: bold;
		font-size: 28px;
		top: 4px;
		right: 4px;
		cursor: pointer;
		user-select: none;
	}

	.icon-box {
		width: 80px;
		height: 80px;
		background: #eee;
		display: inline-flex;
		flex-flow: column;
		justify-content: center;
		align-items: center;
		border-radius: 8px;
		margin-left: 0;
		margin-right: 6px;
		transition: 0.2s;
		position: relative;
	}

	.icon-box:last-of-type {
		margin-right: 0px;
	}

	.icon-box > i {
		display: block;
		font-size: 32px;
		margin-bottom: 6px;
	}

	.icon-box.positive {
		color: #387ef5;
	}

	.icon-box .subtype-badge {
		position: absolute;
		top: 0px;
		right: -3px;
		border-radius: 100%;
	}

	.icon-box:hover .delete-image {
		display: inline-block;
	}

	.subtype-badge {
		position: absolute;
		top: 0px;
		right: -3px;
		border-radius: 100%;
		padding: 4px 8px;
	}

	.delete-image:hover {
		background-color: #c00;
		color: white;
	}

	.urgency-select-item {
		padding-bottom: 0.5em;
		--padding-start: 0em;
		--inner-padding-end: 0;
	}

	.item-label {
		font-size: 18px;
	}

	.planting-info-dropdown > ion-grid > ion-row {
		border-bottom: 1px solid #bbb;
	}
	.planting-info-dropdown > ion-grid > ion-row > ion-col:nth-of-type(even) {
		text-align: right;
		white-space: normal;
	}

	div.multi-planting-info-panel {
		background-color: #eee;
		border-radius: 12px;
		margin-top: 10px;
		overflow-x: scroll;
		overflow-y: hidden;
		white-space: nowrap;
	}

	.planting-info-table {
		vertical-align: top;
		display: inline-block;
	}
	.add-edit-note-modal {
		--height: 100%;
		--max-height: 820px;
	}
</style>

<script>
	import {
		numberFormat,
		round,
		customerConversion,
		queryCurrentLocation,
		getCleansedDate,
	} from "@/utils";

	import { mapState } from "vuex";

	import notes from "@/api/notes";
	import store from "@/store";

	import $ from "jquery";
	import * as turf from "@turf/turf";
	import { defineAsyncComponent } from "vue";
	import {
		IonTitle,
		IonButton,
		IonToolbar,
		IonHeader,
		IonIcon,
		IonLabel,
		IonItem,
		IonBadge,
		IonList,
		IonCol,
		IonRow,
		IonGrid,
		IonTextarea,
		IonToggle,
		IonSelectOption,
		IonSelect,
		IonContent,
		IonPage,
		modalController,
	} from "@ionic/vue";
	import * as icons from "ionicons/icons";

	import { showSelectNoteSubType } from "@/views/Modal/SelectNoteSubType.vue";
	import { showSelectLocationsMap } from "@/views/Modal/SelectLocationsMap.vue";
	import { showSelectNoteLocation } from "@/views/Modal/SelectNoteLocation.vue";

	import "magnific-popup";
	import "magnific-popup/dist/magnific-popup.css";
	import "@fortawesome/fontawesome-free/css/all.css";

	export default {
		name: "AddEditNote",
		components: {
			IonTitle,
			IonButton,
			IonToolbar,
			IonHeader,
			IonIcon,
			IonLabel,
			IonItem,
			IonBadge,
			IonList,
			IonCol,
			IonRow,
			IonGrid,
			IonTextarea,
			IonToggle,
			IonSelectOption,
			IonSelect,
			IonContent,
			IonPage,
		},
		props: {
			originalNote: { type: Object },
			submit: { type: Function },
			planting: { type: Object },
		},
		data() {
			return {
				addedPlantings: new Set(),
				category: null,
				shareLocation: false,
				useNewLocation: false,
				noteLocation: null,
				description: "",
				images: [],
				addedObservations: new Set(),
				removedObservations: new Set(),
				observationValuesById: {},
				editNoteLocationClicked: false,
				noteUrgencyId: null,
				infoCollapsed: true,
				icons,
			};
		},
		created() {
			// Edit
			if (this.editing) {
				this.category = this.noteCategories.find(
					(category) => category.id === this.originalNote.noteTypeId,
				);
				this.observationValuesById =
					this.getObservationSubTypeValuesById(
						this.originalNote.observations,
					);
				this.noteLocation = this.originalNote.location;
				this.noteUrgencyId = this.originalNote.noteUrgencyId;
			}
			// Create
			else {
				this.category = this.noteCategories[0];
				this.observationValuesById =
					this.getObservationSubTypeDefaultValuesById();

				// Default to the highest urgency
				this.noteUrgencyId = this.highestUrgencyType.id;

				// Determine if we have a rough current location
				this.shareLocation = !!(
					this.currentLocation && this.currentLocation.coords
				);

				// If we do, use it as the initial marker location for the note
				if (this.shareLocation)
					this.noteLocation = [this.currentLocation.coords];
			}

			const callback = (location, final) => {
				store.dispatch("setCurrentLocation", location);

				if (!this.editing) {
					this.shareLocation = true;

					// If a location has been set by the user before we get here, don't overwrite it
					if (!(this.noteLocation && this.editNoteLocationClicked))
						this.noteLocation = [this.currentLocation.coords];

					// If no plantings have been selected yet, select the planting from the user location on the final call
					if (!this.addedPlantings.size && final)
						this.setPlantingFromUserLocation();
				}
			};

			// Make a high-detailed query for the current location, to use for a marker when editing
			queryCurrentLocation(
				(location) => callback(location, true),
				(location) => callback(location, false),
			);
		},
		mounted() {
			// Make sure the textarea is properly sized
			setTimeout(() => {
				$("ion-textarea textarea").each(function () {
					const $this = $(this);
					$this.height("auto");
				});
			}, 100);

			// Select the plantings if some were passed (for example from the notes page of a planting)
			if (this.originalNote) {
				this.originalNote.plantingIds.forEach((plantingId) =>
					this.addPlanting(plantingId),
				);
				this.shareLocation = !!this.originalNote.location;
				this.description = this.originalNote.noteText;
				this.useNewLocation = !this.originalNote.location;
			} else if (this.planting) {
				this.addPlanting(this.planting.id);
			}
		},
		methods: {
			dismiss() {
				modalController.dismiss(this);
			},
			selectObservationType(typeId, subTypes) {
				showSelectNoteSubType(this, typeId, subTypes);
			},

			selectNotePlantings() {
				showSelectLocationsMap(this.onChangeSelectedPlantings, {
					title:
						"Select Note " +
						this.user?.plantingRegionDisplayTextPlural,
					selectTypes: ["planting"],
					selected: {
						type: "planting",
						selection: new Set(this.addedPlantings),
					},
				});
			},
			onChangeSelectedPlantings(data) {
				this.addedPlantings = data?.selection;
			},

			editNoteLocation() {
				this.editNoteLocationClicked = true;
				showSelectNoteLocation(this, "display");
			},
			removeNoteLocation() {
				this.noteLocation = null;
			},
			viewImage(imageIndex) {
				const items = this.images.map((x) => {
					return {
						src: x.imageData,
					};
				});

				$.magnificPopup.open(
					{
						items: items,
						type: "image",
						image: {
							titleSrc(item) {
								return `<div style="text-align: center;"><ion-button id="remove-image" image-index="${item.index}" color="danger">Remove</ion-button></div>`;
							},
						},
						gallery: {
							enabled: true,
						},
					},
					imageIndex,
				);

				$("body").off("click", "#remove-image");

				const vm = this;

				$("body").on("click", "#remove-image", function (_e) {
					const imageIndex = +$(this).attr("image-index");
					vm.images.splice(imageIndex, 1);
					$.magnificPopup.close();
				});
			},
			imageCaptured($event) {
				const image = $event.target.files[0];
				const reader = new FileReader();

				reader.onload = (e) => {
					getResizedDataURL(e.target.result, 1920).then((dataUrl) => {
						this.images.push({
							imageName: image.name,
							imageData: dataUrl,
							imageCaptureDateTimeUtc: new Date().toISOString(),
							latitude:
								this.currentLocation &&
								this.currentLocation.coords &&
								this.currentLocation.coords.latitude,
							longitude:
								this.currentLocation &&
								this.currentLocation.coords &&
								this.currentLocation.coords.longitude,
							locationAccuracy:
								this.currentLocation &&
								this.currentLocation.coords &&
								this.currentLocation.coords.accuracy,
						});

						// Clear the file input
						$event.target.value = null;

						// Scroll to keep showing the "take photo" button
						this.$nextTick(() => {
							const wrapper =
								document.getElementById("photosWrapper");
							wrapper.scrollLeft = wrapper.scrollWidth;
						});
					});
				};

				reader.readAsDataURL(image);
			},
			addPlanting(plantingId) {
				this.addedPlantings = this.addedPlantings.add(plantingId);
				this.addedPlantings = new Set(this.addedPlantings);
			},
			removePlanting(plantingId) {
				this.addedPlantings.delete(plantingId);
				this.addedPlantings = new Set(this.addedPlantings);
			},
			addObservationSubType(subType) {
				// Editing note
				if (this.originalNote) {
					// Check if we already have an observation for this subtype
					let existing;
					if (this.originalNote.observations) {
						existing = this.originalNote.observations.find(
							(o) => o.observationSubTypeId == subType.id,
						);
					}
					if (!existing) this.addedObservations.add(subType.id);
					this.removedObservations.delete(subType.id);
				}
				// Creating note
				else {
					this.addedObservations.add(subType.id);
					this.removedObservations.delete(subType.id);
				}
				this.addedObservations = new Set(this.addedObservations);
				this.removedObservations = new Set(this.removedObservations);
			},
			removeObservationSubType(subType) {
				// Editing note
				if (this.originalNote) {
					// Check if we have an observation for this subtype
					let existing;
					if (this.originalNote.observations) {
						existing = this.originalNote.observations.find(
							(o) => o.observationSubTypeId == subType.id,
						);
					}
					if (existing) this.removedObservations.add(subType.id);
					this.addedObservations.delete(subType.id);
				}
				// Creating note
				else {
					this.removedObservations.add(subType.id);
					this.addedObservations.delete(subType.id);
				}
				this.addedObservations = new Set(this.addedObservations);
				this.removedObservations = new Set(this.removedObservations);
			},
			removeAllOfType(typeId) {
				let group = Object.keys(this.groupedObservationSubTypes).find(
					(k) =>
						Object.hasOwn(
							this.groupedObservationSubTypes[k],
							typeId,
						),
				);
				Object.values(
					this.groupedObservationSubTypes[group][typeId],
				).forEach(this.removeObservationSubType);
			},
			setPlantingFromUserLocation() {
				const plantings = this.$store.state.regions;

				if (!plantings) return;

				if (this.currentLocation && this.currentLocation.coords) {
					var userLocation = turf.point([
						this.currentLocation.coords.longitude,
						this.currentLocation.coords.latitude,
					]);

					// Check every planting to see if the user is within it
					for (let planting of plantings) {
						var poly = turf.polygon([
							planting.longLats.map((c) => [c[0], c[1]]),
						]);

						if (turf.booleanPointInPolygon(userLocation, poly)) {
							this.addedPlantings.add(planting.id);
							this.addedPlantings = new Set(this.addedPlantings);

							return;
						}
					}
				}
			},
			getObservationSubTypeDefaultValuesById: function () {
				return Object.values(this.observationSubTypesById).reduce(
					(acc, subType) => {
						var scale = this.observationScalesById[subType.scaleId];

						acc[subType.id] =
							scale.defaultValue === null
								? "" // An empty string is used instead of null in order to properly interface with the <select> element
								: scale.defaultValue;

						return acc;
					},
					{},
				);
			},
			getObservationSubTypeValuesById: function (observations) {
				// Populate default values
				var result = this.getObservationSubTypeDefaultValuesById();

				// Fill in values from observations
				if (observations) {
					observations.forEach(
						(observation) =>
							(result[observation.observationSubTypeId] =
								observation.scaleValue === null
									? "" // An empty string is used instead of null in order to properly interface with the <select> element
									: observation.scaleValue),
					);
				}

				return result;
			},
			updateUrgencyType(event) {
				this.noteUrgencyId = parseInt(event.target.value);
			},
			updateUrgencyTypeBool(isHighUrgency) {
				// For ion-toggle select
				this.noteUrgencyId = isHighUrgency
					? this.highestUrgencyType.id
					: this.lowestUrgencyType.id;
			},
			round,
			customerConversion,
			numberFormat,
			dateString: function (date) {
				if (typeof date === "string") date = getCleansedDate(date);
				if (!date || !(date instanceof Date)) return;

				return date.toLocaleDateString("en-US", {
					weekday: "short",
					month: "numeric",
					day: "numeric",
					year: "numeric",
				});
			},
		},
		computed: {
			...mapState([
				"noteUrgencyTypesById",
				"noteCategories",
				"user",
				"online",
				"currentLocation",
				"customerUnits",
				"groupedObservationSubTypes",
				"observationTypesById",
				"observationCategoriesById",
				"observationScalesById",
				"observationSubTypes",
			]),
			editing() {
				return !!this.originalNote;
			},
			highestUrgencyType() {
				// Returns the Urgency Type of highest value for this customer or null
				if (!Object.keys(this.noteUrgencyTypesById).length) return {};
				return Object.values(this.noteUrgencyTypesById).reduce(
					(max, urgencyType) =>
						max.value > urgencyType.value ? max : urgencyType,
				);
			},
			lowestUrgencyType() {
				// Returns the Urgency Type of highest value for this customer or null
				if (!Object.keys(this.noteUrgencyTypesById).length) return {};
				return Object.values(this.noteUrgencyTypesById).reduce(
					(min, urgencyType) =>
						min.value < urgencyType.value ? min : urgencyType,
				);
			},
			plantings() {
				return Object.values(this.$store.state.regions).reduce(
					(acc, cur) => {
						if (this.addedPlantings.has(cur.id)) acc.push(cur);
						return acc;
					},
					[],
				);
			},
			observationSubTypesById() {
				return this.observationSubTypes.reduce((acc, subType) => {
					acc[subType.id] = subType;
					return acc;
				}, {});
			},
			observations() {
				// Start with original observations
				let result = [];

				if (this.originalNote && this.originalNote.observations)
					result = JSON.parse(
						JSON.stringify(this.originalNote.observations),
					);

				// Remove anything we've removed whilst editing note
				result = result.reduce((acc, cur) => {
					if (!this.removedObservations.has(cur.observationSubTypeId))
						acc.push(cur);
					return acc;
				}, []);

				// Add fake observation objects for any newly-selected observations
				this.addedObservations.forEach((subTypeId) => {
					result.push({
						id: null,
						noteId: this.originalNote ? this.originalNote.id : null,
						observationSubTypeId: subTypeId,
						observationDate: new Date().toISOString(),
					});
				});

				// Load scale values and IDs
				result.forEach((observation) => {
					observation.scaleValue =
						this.observationValuesById[
							observation.observationSubTypeId
						];
					observation.scaleId =
						this.observationSubTypesById[
							observation.observationSubTypeId
						].scaleId;
				});

				return result;
			},
			selectedSubTypesByTypeId() {
				// Get dictionary of types: selected subtypes

				let subTypes = this.$store.state.observationSubTypes;
				let result = {};

				// Check each observation
				for (const key in this.observations) {
					let obs = this.observations[key];

					// Find the subType object for this observation
					let subType = subTypes.find(
						(t) => t.id == obs.observationSubTypeId,
					);
					if (subType) {
						result[subType.typeId] = result[subType.typeId] || [];
						// Store { typeId: [..., subType object] }
						result[subType.typeId].push(subType);
					}
				}

				return result;
			},
			selectedSubTypesLength(id) {
				// Get how many subTypes are selected for typeId id

				if (!this.selectedSubTypesByTypeId[id]) return 0;
				return this.selectedSubTypesByTypeId[id].length();
			},
			urgencyToggleChecked() {
				// Should the ion-toggle be selected
				return (
					!this.editing ||
					this.originalNote.noteUrgencyId ==
						this.highestUrgencyType.id
				);
			},
			infoExpandedClass() {
				return this.infoCollapsed ? "" : "expanded";
			},
		},
	};

	export function showAddEditNote(planting, originalNote) {
		modalController
			.create({
				cssClass: "add-edit-note-modal",
				component: defineAsyncComponent(
					() => import("@/views/Modal/AddEditNote.vue"),
				),
				componentProps: {
					planting,
					originalNote,
					submit: (
						plantingIds,
						category,
						shareLocation,
						useNewLocation,
						description,
						images,
						addedObservations,
						removedObservations,
						observations,
						observationValuesById,
						observationSubTypesById,
						noteLocation,
						noteUrgencyId,
					) => {
						if (observations) {
							observations.forEach((observation) => {
								// Convert back to nullable int format
								if (observation.scaleValue === "")
									observation.scaleValue = null;
							});
						}

						let noteLocationAccuracy = null;

						if (noteLocation && noteLocation.length) {
							// Grab the accuracy value (if there is one)
							noteLocationAccuracy = noteLocation[0].accuracy;

							// Prepare the location for serialization, as a `GeoLocationCoordinates` object will not be on its own
							noteLocation = noteLocation.map((x) => {
								return {
									latitude: x.latitude,
									longitude: x.longitude,
								};
							});
						}

						// Create
						if (!originalNote) {
							const note = {
								_noteId: new Date().getTime(),
								plantingIds: plantingIds,
								noteTypeId: category.id,
								noteUrgencyId: noteUrgencyId,
								noteText: description,
								createdDate: new Date().toISOString(),
								createdByUser: store.state.user.name,
								createdByUserId: store.state.user.userId,
								location: noteLocation,
								observations: observations,
								accuracy: noteLocationAccuracy,
								deletable: true,
								editable: true,
							};

							// Let server know about note
							notes.create(note, images);
						}
						// Edit
						else {
							let location = noteLocation;
							let accuracy = null;

							if (shareLocation) {
								location = useNewLocation
									? [
											{
												latitude:
													this.currentLocation.coords
														.latitude,
												longitude:
													this.currentLocation.coords
														.longitude,
											},
										]
									: noteLocation;

								accuracy = useNewLocation
									? noteLocationAccuracy
									: originalNote.accuracy;
							}

							// Build changed observations lists
							let observationsToAdd = [
								...addedObservations,
							].reduce((acc, subTypeId) => {
								acc.push({
									id: null,
									observationSubTypeId: subTypeId,
									observationDate: new Date().toISOString(),
									scaleValue:
										observationValuesById[subTypeId],
									scaleId:
										observationSubTypesById[subTypeId]
											.scaleId,
								});
								return acc;
							}, []);

							let observationsToRemove;

							if (originalNote.observations) {
								observationsToRemove = [
									...removedObservations,
								].reduce((acc, cur) => {
									let observation =
										originalNote.observations.find(
											(o) =>
												o.observationSubTypeId == cur,
										);
									acc.push({
										id: observation.id,
										noteId: observation.noteId,
										observationSubTypeId:
											observation.observationSubTypeId,
									});
									return acc;
								}, []);

								// Handle observations that weren't removed, but have a new scale value
								originalNote.observations.forEach(
									(observation) => {
										var subTypeId =
											observation.observationSubTypeId;

										// Only check unchanged observations (i.e. those that weren't added or removed)
										if (
											!addedObservations.has(subTypeId) &&
											!removedObservations.has(subTypeId)
										) {
											var existingValue =
												observation.scaleValue;

											var newValue =
												observationValuesById[
													subTypeId
												];

											// Ensure that empty string and null values are seen as equivalent
											if (newValue === "")
												newValue = null;

											// If the value changed, remove the old observation and create a new observation the the new value
											if (existingValue !== newValue) {
												// Add new
												observationsToAdd.push({
													id: null,
													observationSubTypeId:
														subTypeId,
													observationDate:
														new Date().toISOString(),
													scaleValue: newValue,
													scaleId:
														observationSubTypesById[
															subTypeId
														].scaleId,
												});

												// Remove old
												observationsToRemove.push({
													id: observation.id,
													noteId: observation.noteId,
													observationSubTypeId:
														observation.observationSubTypeId,
												});
											}
										}
									},
								);
							}

							// Get a copy of the original note, with the modifications merged in
							const note = $.extend({}, originalNote, {
								_noteId: originalNote.noteId,
								plantingIds: plantingIds,
								noteTypeId: category && category.id,
								noteUrgencyId: noteUrgencyId,
								noteText: description,
								location: location,
								accuracy: accuracy,
								observations: observations,
								observationsToAdd: observationsToAdd,
								observationsToRemove: observationsToRemove,
							});

							// Let server know about note
							notes.edit(note, images);
						}

						// Close modal
						modalController.dismiss(this);

						// Close sliding option(s)
						Array.from(
							document.getElementsByTagName("ion-list"),
						).forEach((list) => list.closeSlidingItems());
					},
				},
			})
			.then((m) => m.present());
	}

	// Takes a data URL and returns the data URI corresponding to the resized image within the maximum dimensions
	function getResizedDataURL(dataUrl, maximumDimension) {
		return new Promise((resolve) => {
			// We create an image to receive the Data URI
			const img = document.createElement("img");

			// When the event "onload" is triggered we can resize the image.
			img.onload = function () {
				let width = img.width;
				let height = img.height;

				const ratio = width / height;

				if (width > maximumDimension) {
					width = maximumDimension;
					height = Math.round(maximumDimension / ratio);
				}

				if (height > maximumDimension) {
					height = maximumDimension;
					width = Math.round(maximumDimension * ratio);
				}

				// We create a canvas and get its context.
				const canvas = document.createElement("canvas");
				const ctx = canvas.getContext("2d");

				// We set the dimensions at the wanted size.
				canvas.width = width;
				canvas.height = height;

				// We resize the image with the canvas method drawImage();
				ctx.drawImage(this, 0, 0, width, height);

				return resolve(canvas.toDataURL("image/jpeg", 0.9));
			};

			// We put the Data URI in the image's src attribute
			img.src = dataUrl;
		});
	}
</script>
