<!--
    Generic Full-Page Location Select Modal
     Can select Plantings, Lots. Also has a half-screen map that can be used for selection.

    For a smaller select (no map), see SelectLocations.vue
    -->
<template>
	<ion-page>
		<!-- Toolbar -->
		<ion-header>
			<ion-toolbar :color="onlineColor">
				<ion-button
					slot="end"
					color="transparent"
					style="margin-right: 8px; overflow: hidden"
					@click="dismissModal"
				>
					{{ $t("common.done") }}
				</ion-button>
				<ion-title>
					{{ title || $t("location.select-location") }}
				</ion-title>
			</ion-toolbar>

			<!-- Planting/Lot Toggle Bar -->
			<ion-toolbar
				class="type-select-toolbar"
				v-if="this.selectTypes.length > 1"
				:color="onlineColor"
			>
				<ion-segment
					class="selection-segment"
					@ionChange="selectionType = $event.target.value"
					:value="selectionType"
				>
					<ion-segment-button class="segment-button" value="planting">
						<ion-icon :icon="leaficon"></ion-icon>
						{{ $t("location.planting") }}
					</ion-segment-button>
					<ion-segment-button class="segment-button" value="lot">
						<ion-icon :icon="mapicon"></ion-icon>
						{{ $t("location.lot") }}
					</ion-segment-button>
					<!--Sublot selection: Disabled until further notice-->
					<ion-segment-button v-if="false" value="sublot">
						<ion-icon :icon="mapicon"></ion-icon>
						{{ $t("location.sublot") }}
					</ion-segment-button>
				</ion-segment>
			</ion-toolbar>
		</ion-header>

		<!-- Select Page -->
		<ion-content v-show="selectionType == 'planting'">
			<!-- Layer Style Toggle -->
			<div class="layer-style-toggle">
				<input
					type="radio"
					name="layer-style-note-plantings"
					id="streets-note-plantings"
					value="map"
					@change="changeBaseMap('outdoors-v11')"
					checked
				/>
				<label for="streets-note-plantings" style="min-width: 70px">
					{{ $t("location.map") }}
				</label>

				<input
					type="radio"
					name="layer-style-note-plantings"
					id="satellite-note-plantings"
					value="satellite"
					@change="changeBaseMap('satellite-streets-v11')"
				/>
				<label for="satellite-note-plantings" style="min-width: 104px">
					{{ $t("location.satellite") }}
				</label>
			</div>

			<!-- Plantings Map -->
			<div
				ref="selectionMap"
				id="selection-map"
				style="width: 100%; height: 40%"
			></div>

			<!-- Plantings Search/Select List -->
			<div
				id="selection-list"
				style="
					width: 100%;
					height: 59%;
					display: flex;
					flex-direction: column;
				"
			>
				<form @submit.prevent="centerMapOnFilteredPlantings">
					<ion-searchbar
						@ionInput="plantingSearchQuery = $event.target.value"
						:placeholder="$t('common.search')"
					></ion-searchbar>
				</form>

				<!-- Map filters -->
				<ion-item class="map-filter">
					<ion-grid>
						<ion-row>
							<ion-col>
								<ion-toggle
									:checked="!filterByMapView"
									@ionChange="
										toggleFilterByMapView(
											!$event.target.checked,
										)
									"
								></ion-toggle>
								<ion-label
									style="
										height: 100%;
										padding-left: 10px;
										vertical-align: middle;
										display: inline-block;
									"
								>
									{{
										$t(
											"location.selection.list-plantings-out-of-view",
										)
									}}
								</ion-label>
							</ion-col>
							<ion-col size="auto">
								<ion-select
									interface="popover"
									:value="resultInfoField"
									@ionChange="
										resultInfoField = $event.target.value
									"
									style="min-width: 105px"
								>
									<ion-select-option value="commodity">{{
										$t("planting.commodity")
									}}</ion-select-option>
									<ion-select-option value="proximity">{{
										$t("location.proximity")
									}}</ion-select-option>
								</ion-select>
							</ion-col>
						</ion-row>
					</ion-grid>
				</ion-item>

				<!-- Planting selection list -->
				<ion-list
					style="overflow-y: scroll; flex: 1; margin-bottom: 2px"
				>
					<ion-item
						v-for="planting in visiblePlantings"
						:key="planting.id"
						:color="planting.selected ? 'light' : ''"
						@click="togglePlanting(planting.id)"
					>
						<ion-label>
							<ion-text color="dark" style="font-weight: bold">
								{{ planting.plantingRegion }}
							</ion-text>

							<br />

							<ion-text color="medium" style="font-size: 90%">
								{{ planting.environmentalRegionName }}
								{{ plantingSubtext(planting) }}
							</ion-text>
						</ion-label>
						<i
							slot="start"
							:class="{
								'far fa-circle': !selectedPlantings.has(
									planting.id,
								),
								'fas fa-check-circle': selectedPlantings.has(
									planting.id,
								),
							}"
							:style="{
								color: selectedPlantings.has(planting.id)
									? 'rgb(66, 157, 119)'
									: null,
							}"
						></i>
						<ion-note
							v-if="resultInfoField == 'commodity'"
							slot="end"
							>{{ planting.plantedCommodity }}</ion-note
						>
						<ion-note
							v-else-if="resultInfoField == 'proximity'"
							slot="end"
							>{{
								Math.round(getDistanceToUser(planting) * 10) /
								10
							}}
							{{ $t("measure.mile-abv") }}</ion-note
						>
					</ion-item>
					<ion-item>
						<!-- TODO: Add virtual scroll to this list -->
						<ion-label
							v-if="
								orderedPlantings.length >
								visiblePlantings.length
							"
						>
							{{ $t("lot-search.search-more-notice") }}
						</ion-label>
					</ion-item>
				</ion-list>
			</div>
		</ion-content>

		<!-- Lot Select UI -->
		<ion-content v-show="selectionType == 'lot'">
			<!-- Lot Search/Select List -->
			<div
				style="
					width: 100%;
					height: 100%;
					display: flex;
					flex-direction: column;
				"
			>
				<form>
					<ion-searchbar
						@ionInput="lotSearchQuery = $event.target.value"
						:placeholder="$t('common.search')"
					></ion-searchbar>
				</form>

				<!-- Lots List -->
				<ion-list
					id="selectLotList"
					style="overflow-y: scroll; flex: 1; margin-bottom: 2px"
				>
					<ion-item
						v-for="lot in visibleLotsList"
						:key="lot.uid"
						:color="lot.selected ? 'light' : ''"
						detail="false"
						@click="toggleLot(lot.uid)"
					>
						<ion-label>
							<ion-text color="dark" style="font-weight: bold">
								{{ lot.fullName }}
							</ion-text>
						</ion-label>
						<i
							slot="start"
							:class="{
								'far fa-circle': !selectedLots.has(lot.uid),
								'fas fa-check-circle': selectedLots.has(
									lot.uid,
								),
							}"
							:style="{
								color: selectedLots.has(lot.uid)
									? 'rgb(66, 157, 119)'
									: null,
							}"
						></i>
					</ion-item>
					<ion-item>
						<ion-label
							v-if="
								orderedLotsList.length >
									visibleLotsList.length &&
								lotSearchQuery == ''
							"
						>
							{{
								$t("lot-search.search-more-thing-notice", {
									thing: $t("location.lot"),
								})
							}}
						</ion-label>
					</ion-item>
				</ion-list>
			</div>
		</ion-content>
	</ion-page>
</template>

<style>
	.select-locations-map-modal {
		--min-height: 90%;
	}

	i.fa-check-circle,
	i.fa-circle {
		font-size: 24px;
		margin-right: 12px;
	}

	/* Map */
	.map-filter ion-toggle,
	.map-filter ion-select {
		zoom: 0.8;
	}

	.map-filter {
		font-size: 16px;
	}

	.map-filter ion-grid,
	.map-filter ion-col {
		padding-left: 0;
		padding-right: 0;
	}

	/* Toggle Bar */
	.selection-segment {
		width: 100%;
		min-height: 48px;
	}

	.segment-button {
		max-width: none !important;
		--border-width: 0 !important;
	}

	.type-select-toolbar {
		--padding-top: 0;
	}
</style>

<script>
	import { mapState, mapGetters } from "vuex";
	import { defineAsyncComponent } from "vue";

	import {
		IonButton,
		IonTitle,
		IonToolbar,
		IonIcon,
		IonSegment,
		IonHeader,
		IonSearchbar,
		IonToggle,
		IonLabel,
		IonCol,
		IonRow,
		IonGrid,
		IonSelect,
		IonSelectOption,
		IonNote,
		IonText,
		IonList,
		IonContent,
		IonItem,
		IonSegmentButton,
		IonPage,
		modalController,
	} from "@ionic/vue";
	import { leaf as leaficon, map as mapicon } from "ionicons/icons";

	import "mapbox-gl/dist/mapbox-gl.css";
	import mapboxgl from "mapbox-gl";
	import moment from "moment";
	import { mapboxToken } from "seedgreen-shared-scripts";
	import * as turf from "@turf/turf";
	import { changeBaseMap } from "@/utils";

	export default {
		name: "SelectLocationsWithMap",
		components: {
			IonButton,
			IonTitle,
			IonToolbar,
			IonIcon,
			IonSegment,
			IonHeader,
			IonSearchbar,
			IonToggle,
			IonLabel,
			IonCol,
			IonRow,
			IonGrid,
			IonSelect,
			IonSelectOption,
			IonNote,
			IonText,
			IonList,
			IonContent,
			IonItem,
			IonSegmentButton,
			IonPage,
		},
		props: {
			callback: { type: Function, required: true },
			selectTypes: { type: Array, required: true },
			title: String,
			preselected: Object,
		},
		data() {
			return {
				leaficon,
				mapicon,

				map: null,
				mapLoaded: false,
				mapVisiblePlantings: null,
				filterByMapView: false,
				regionDistances: {},

				displayPlantingsCount: 50,
				displayLotsCount: 50,
				plantingSearchQuery: "",
				lotSearchQuery: "",
				resultInfoField: "commodity",

				selectionType: this.preselected?.type || this.selectTypes[0], // Are we selecting by planting or by lot?
				selectedPlantings:
					this.preselected?.type === "planting"
						? this.preselected.selection
						: new Set(),
				selectedLots:
					this.preselected?.type === "lot"
						? this.preselected.selection
						: new Set(),
			};
		},
		created() {
			mapboxgl.accessToken = mapboxToken;
		},
		mounted() {
			const mapParams = {
				container: this.$refs.selectionMap,
				style: "mapbox://styles/mapbox/streets-v11",
				zoom: 14,
			};

			if (this.currentLocation && this.currentLocation.coords)
				mapParams.center = [
					this.currentLocation.coords.longitude,
					this.currentLocation.coords.latitude,
				];

			this.map = new mapboxgl.Map(mapParams);

			// Show zoom/orientation controls
			this.map.addControl(new mapboxgl.NavigationControl());

			// Show "locate me" control
			this.map.addControl(
				new mapboxgl.GeolocateControl({
					positionOptions: { enableHighAccuracy: true },
					trackUserLocation: true,
				}),
			);

			this.map.on("load", () => {
				// Load icons
				Promise.all(
					Object.entries({
						"marker-hover": "/images/markers/marker-hover.png",
					}).map(
						(img) =>
							new Promise((resolve) => {
								this.map.loadImage(img[1], (error, res) => {
									this.map.addImage(img[0], res);
									resolve();
								});
							}),
					),
				).then(() => {
					this.mapLoaded = true;

					if (!this.map.center) this.centerMapOnFilteredPlantings();

					this.centerMapOnSelectedPlantings();

					// Load planting boundaries onto map
					this.map.addSource("plantings", {
						type: "geojson",
						data: {
							type: "FeatureCollection",
							features: [],
						},
					});

					this.map.addLayer({
						id: "planting-fills",
						type: "fill",
						source: "plantings",
						layout: {},
						minZoom: 1,
						paint: {
							"fill-color": [
								"case",
								["get", "selected"],
								"#EAD315",
								"#627BC1",
							],
							"fill-opacity": 0.6,
						},
					});

					this.map.addLayer({
						id: "planting-borders",
						type: "line",
						source: "plantings",
						layout: {},
						paint: {
							"line-color": [
								"case",
								["get", "selected"],
								"#888888",
								"#627BC1",
							],
							"line-width": 1.5,
						},
					});

					// Select planting on click
					this.map.on("click", "planting-fills", (e) => {
						this.togglePlanting(e.features[0].id);
					});

					// Filter plantings on map move
					this.map.on("moveend", this.computeVisiblePlantings);
					this.map.resize();

					this.refreshPlantingPolygons();
					this.computeVisiblePlantings();
				});
			});

			setTimeout(this.getPlantingFromUserLocation, 500);
		},
		methods: {
			/* Map */
			changeBaseMap(baseMap) {
				changeBaseMap(this.map, baseMap);
			},
			centerMapOnSelectedPlantings() {
				var plantingsJson = this.getPlantingsGeoJson();

				if (plantingsJson && plantingsJson.plantings) {
					plantingsJson = plantingsJson.plantings;

					// Grab selected planting
					plantingsJson.features = plantingsJson.features.filter(
						(feature) => feature.properties.selected,
					);

					// Fit map to selected planting
					if (plantingsJson.features.length) {
						this.map.fitBounds(turf.bbox(plantingsJson), {
							padding: 50,
							animate: true,
						});
					}
				}
			},
			centerMapOnFilteredPlantings() {
				// Grab search-filtered plantings
				var plantingsJson = this.getPlantingsGeoJson(
					this.searchFilteredPlantings,
				);

				if (plantingsJson && plantingsJson.plantings) {
					plantingsJson = plantingsJson.plantings;

					// Fit map to selected planting(s)
					if (plantingsJson.features.length) {
						this.map.fitBounds(turf.bbox(plantingsJson), {
							padding: 50,
							animate: false,
						});
					}
				}
			},
			toggleFilterByMapView(value) {
				this.filterByMapView = value;
				this.computeVisiblePlantings();
			},
			computeVisiblePlantings() {
				const boundsPolygon = turf.bboxPolygon(
					turf.bbox(turf.lineString(this.map.getBounds().toArray())),
				);

				const visiblePlantings = this.searchFilteredPlantings.filter(
					(planting) =>
						!turf.booleanDisjoint(
							boundsPolygon,
							turf.polygon([planting.longLats]),
						),
				);

				this.mapVisiblePlantings = new Set(
					visiblePlantings.map((planting) => planting.id),
				);
			},
			getPlantingsGeoJson(plantings) {
				plantings = plantings || this.$store.state.regions;

				if (!plantings) return "";

				var plantingsJson = {
					type: "FeatureCollection",
					features: plantings
						.filter((p) => p.longLats && p.longLats.length)
						.map((p) => {
							var coordinates = p.longLats.map((coords) => [
								coords[0],
								coords[1],
							]);

							return {
								id: p.id,
								type: "Feature",
								geometry: {
									type: "Polygon",
									coordinates: [coordinates],
								},
								properties: {
									selected: p.selected,
								},
							};
						}),
				};

				return {
					plantings: plantingsJson,
				};
			},
			refreshPlantingPolygons() {
				var plantingsSource = this.map.getSource("plantings");

				if (!plantingsSource) return;

				var json = this.getPlantingsGeoJson(
					this.searchFilteredPlantings,
				);

				plantingsSource.setData(json.plantings);
			},

			dismissModal() {
				// Remove any plantings that are no longer visible in the list
				this.selectedPlantings.forEach((p) => {
					if (
						!this.orderedPlantings.some(
							(planting) => planting.id === p.id,
						)
					)
						this.selectedPlantings.delete(p.id);
				});

				let data = {
					type: this.selectionType,
					selection:
						this.selectionType === "planting"
							? this.selectedPlantings
							: this.selectedLots,
				};

				this.callback(data);
				modalController.dismiss();
			},

			togglePlanting(plantingId) {
				if (!this.selectedPlantings.has(plantingId))
					this.selectedPlantings.add(plantingId);
				else this.selectedPlantings.delete(plantingId);

				//orderedPlantings listens to selected planting, which triggers refreshPlantingPolygons
			},
			toggleLot(lotId) {
				if (!this.selectedLots.has(lotId)) this.selectedLots.add(lotId);
				else this.selectedLots.delete(lotId);
			},
			getDistanceToUser(planting) {
				if (!this.currentLocation?.coords) return 0;

				if (this.regionDistances[planting.id])
					return this.regionDistances[planting.id];

				var userLocation = turf.point([
					this.currentLocation.coords.longitude,
					this.currentLocation.coords.latitude,
				]);

				var poly = turf.polygon([
					planting.longLats.map((c) => [c[0], c[1]]),
				]);
				var plantingCenter = turf.centerOfMass(poly);

				this.regionDistances[planting.id] = turf.distance(
					userLocation,
					plantingCenter,
					{ units: "miles" },
				);

				return this.regionDistances[planting.id];
			},
			plantingNumber(planting) {
				return planting.customData?.planting_number;
			},
			/* Get a string that can contain planting number and organic information */
			plantingSubtext(planting) {
				let plantingNumber = this.plantingNumber(planting);
				let organicString =
					planting.organicAuthorityLabel?.label ||
					planting.organicAuthority?.name ||
					"";

				if (!plantingNumber && !organicString) return;

				let plantingNumberString =
					plantingNumber != null ? `#${plantingNumber} ` : "";
				let combinedString = [plantingNumberString, organicString].join(
					" ",
				);

				return `(${combinedString.trim()})`;
			},
		},
		computed: {
			...mapState(["currentLocation", "lotViewsById", "regions"]),
			...mapGetters(["onlineColor"]),
			/** Filtered lots for display list, sliced for performance */
			visibleLotsList() {
				return this.orderedLotsList.slice(
					0,
					this.selectedLots.size + this.displayLotsCount,
				);
			},
			orderedLotsList() {
				let searchLots = this.searchFilteredLots;

				searchLots.forEach(
					(l) => (l.selected = this.selectedLots.has(l.uid)),
				);

				// Order by selected first
				return searchLots.sort((a, b) => {
					return a.selected > b.selected ? -1 : 1;
				});
			},
			searchFilteredLots() {
				return Object.values(this.lotViewsById).filter((l) => {
					if (!this.lotSearchQuery) return true;

					return (
						new RegExp(this.lotSearchQuery, "i").test(l.name) ||
						l.selected
					);
				});
			},
			/** Reduced plantings list to show on checklist (max 50 items) */
			visiblePlantings() {
				//avoid rendering too many region menu items at once (search bar can find the rest)
				return this.orderedPlantings.slice(
					0,
					this.selectedPlantings.size + this.displayPlantingsCount,
				);
			},
			orderedPlantings() {
				// Ordered and filtered plantings
				let searchPlantings = this.searchFilteredPlantings;

				// Add 'selected' property based off of checklist selection
				searchPlantings.forEach(
					(p) => (p.selected = this.selectedPlantings.has(p.id)),
				);

				// Filter by map view if selected
				if (this.filterByMapView && this.mapVisiblePlantings)
					searchPlantings = searchPlantings.filter((p) =>
						this.mapVisiblePlantings.has(p.id),
					);

				// Order by checked first, then by distance to user
				return searchPlantings.sort((a, b) => {
					if (a.selected != b.selected)
						return a.selected > b.selected ? -1 : 1;
					else
						return (
							this.getDistanceToUser(a) -
							this.getDistanceToUser(b)
						);
				});
			},
			/** List of plantings filtered by planting search query */
			searchFilteredPlantings() {
				// Order by checked first, then by distance to user
				return this.regions.filter((p) => {
					if (p.isIrrigationBlock) return false;

					// Plantings harvested more than a week ago shouldn't be shown
					if (
						moment(p.currentHarvestDateRaw).isBefore(
							moment().subtract(7, "days"),
							"day",
						)
					)
						return false;

					if (!this.plantingSearchQuery) return true;

					const plantingName =
						p.environmentalRegionName + " " + p.plantingRegion;

					return new RegExp(this.plantingSearchQuery, "i").test(
						plantingName,
					);
				});
			},
		},
		watch: {
			orderedPlantings() {
				this.refreshPlantingPolygons();
			},
			filterByMapView() {
				this.refreshPlantingPolygons();
			},
		},
	};

	/*
    callback: callback function that returns selected locations data
    options:
        title:          (str) Title of modal shown,
        selectTypes:    (arr) Array of allowed selection types ("planting", "lot")
        selected:       (obj) Set of locations to be pre-selected (same format as return data)
    */
	export function showSelectLocationsMap(callback, options) {
		modalController
			.create({
				component: defineAsyncComponent(
					() => import("@/views/Modal/SelectLocationsMap.vue"),
				),
				componentProps: {
					callback: callback,
					title: options.title,
					selectTypes: options.selectTypes || ["planting", "lot"],
					preselected: options.selected,
				},
				cssClass: "select-locations-map-modal",
			})
			.then((m) => m.present());
	}
</script>
