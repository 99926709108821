<template>
	<ion-page>
		<ion-header>
			<ion-toolbar :color="online ? 'branding' : 'dark'">
				<ion-title slot="start">
					{{
						editing
							? $t("task.edit-task-request")
							: $t("task.create-task-request")
					}}
				</ion-title>

				<ion-button @click="dismiss" slot="end" color="transparent">
					{{ $t("common.cancel") }}
				</ion-button>
			</ion-toolbar>
		</ion-header>

		<ion-content class="ion-padding" id="task-request-form">
			<!-- Task request properties list -->
			<ion-list lines="full" inset="false">
				<!-- Planting/Lot Selection -->
				<ion-item
					button
					lines="none"
					id="planting-lot-select-button"
					@click="showSelectLocationsMap"
					:detail="!showPlantingInfoButton"
				>
					<ion-icon
						:icon="mapicon"
						slot="start"
						style="margin-right: 6px"
					>
					</ion-icon>

					<ion-label>
						{{
							fieldNames || $t("location.select-location") + "..."
						}}
					</ion-label>

					<ion-button
						v-if="showPlantingInfoButton"
						@click="
							infoCollapsed = !infoCollapsed;
							$event.stopPropagation();
						"
						color="primary"
						slot="end"
						class="info-button"
					>
						<ion-icon
							:icon="informationcircleoutlineicon"
							style="margin-right: 3px"
						></ion-icon>
						{{ $t("info-dropdown.info") }}
					</ion-button>
				</ion-item>

				<div
					class="planting-info-dropdown collapsable-panel multi-planting-info-panel"
					:class="infoExpandedClass"
					v-if="selectedPlantings.length"
				>
					<ion-grid
						v-for="planting in selectedPlantings"
						:key="planting.id"
						class="planting-info-table"
						:style="{
							width:
								selectedPlantings.length === 1 ? '100%' : '80%',
						}"
					>
						<ion-row v-if="selectedPlantings.length > 1">
							<ion-col
								style="
									font-weight: bold;
									border-bottom: solid 1px #999;
								"
							>
								{{ planting.plantingRegion }}
							</ion-col>
						</ion-row>
						<ion-row>
							<ion-col> {{ $t("planting.commodity") }}: </ion-col>
							<ion-col>
								{{ planting.displayCrop }}
							</ion-col>
						</ion-row>
						<ion-row>
							<ion-col> {{ $t("planting.method") }}: </ion-col>
							<ion-col>
								{{
									capitalizeFirstLetter(planting.plantMethod)
								}}
							</ion-col>
						</ion-row>
						<ion-row>
							<ion-col>
								{{ this.$tc("measure.acre", 2) }}:
							</ion-col>
							<ion-col>
								{{ planting.displayAcres }}
								{{ this.$tc("measure.acre-abv", 2) }}
							</ion-col>
						</ion-row>
						<ion-row>
							<ion-col> {{ $t("planting.bed-count") }}: </ion-col>
							<ion-col>
								{{ planting.currentBedCount }}
							</ion-col>
						</ion-row>
						<ion-row>
							<ion-col>
								{{ $t("planting.plant-spacing") }}:
							</ion-col>
							<ion-col>
								{{ planting.currentPlantSpacing }}
								{{ this.$tc("measure.inch-abv", 2) }}
							</ion-col>
						</ion-row>
						<ion-row>
							<ion-col> {{ $t("planting.wet-date") }}: </ion-col>
							<ion-col>
								{{ dateString(planting.wetDate) }}
							</ion-col>
						</ion-row>
						<ion-row>
							<ion-col>
								{{ $t("planting.harvest-date") }}:
							</ion-col>
							<ion-col>
								{{ dateString(planting.currentHarvestDateRaw) }}
							</ion-col>
						</ion-row>
					</ion-grid>
				</div>

				<!-- Task Type / Date -->
				<ion-grid>
					<ion-row>
						<ion-col>
							<ion-item lines="none">
								<ion-label position="stacked">
									{{ $t("task.type.type") }}
								</ion-label>
								<ion-select
									name="taskTypeSelect"
									:value="taskTypeValue"
									@ionChange="
										onChangeTaskType(
											parseInt($event.target.value),
										)
									"
									interface="popover"
								>
									<ion-select-option
										:value="TaskTypes.General"
										>{{
											$t("task.type.general")
										}}</ion-select-option
									>
									<ion-select-option
										v-if="canScheduleThin"
										:value="TaskTypes.Thinning"
									>
										{{ $t("task.type.thinning") }}
									</ion-select-option>
									<ion-select-option
										v-if="canScheduleWeeding"
										:value="TaskTypes.Weeding"
									>
										{{ $t("task.type.weeding") }}
									</ion-select-option>
								</ion-select>
							</ion-item>
						</ion-col>
						<ion-col>
							<ion-item lines="none" @click="pickDate">
								<ion-label position="stacked"
									>{{ $t("task.task-date") }} *</ion-label
								>
								<ion-text class="date-input">
									{{ dateString(this.taskDateValue) }}
								</ion-text>
							</ion-item>
						</ion-col>
					</ion-row>
				</ion-grid>

				<!-- Title (Description) -->
				<ion-item lines="none">
					<ion-label position="stacked" style="opacity: 1">
						{{ $t("common.description") }} *
						<small
							style="float: right"
							:style="{
								color: remainingTitleChars === 0 ? 'red' : '',
							}"
						>
							{{
								$t("common.n-chars-remaining", {
									n: remainingTitleChars,
								})
							}}
						</small>
					</ion-label>
					<ion-input
						type="text"
						class="text-blob"
						:disabled="this.taskTypeValue !== TaskTypes.General"
						maxlength="45"
						:value="titleValue"
						@ionChange="onChangeTitle($event.target.value)"
					>
					</ion-input>
				</ion-item>

				<!-- Request Comments -->
				<ion-item lines="none">
					<ion-label position="stacked">{{
						$tc("common.comment", 2)
					}}</ion-label>
					<ion-textarea
						class="textarea-blob"
						autogrow
						name="comments"
						:value="commentsValue"
						@ionChange="onChangeComments($event.target.value)"
					>
					</ion-textarea>
				</ion-item>

				<!-- Thin/Weed Field Shared Options -->
				<template v-if="taskTypeValue === TaskTypes.Thinning">
					<ion-list-header class="section-header">{{
						$t("task.type.thinning")
					}}</ion-list-header>

					<ion-grid>
						<ion-row>
							<ion-col>
								<!-- Plant Spacing -->
								<ion-item lines="none">
									<ion-label position="stacked">{{
										$t("planting.plant-spacing")
									}}</ion-label>
									<ion-input
										:placeholder="$t('planting.spacing')"
										:value="plantSpacingValue"
										@ionChange="
											onChangePlantSpacing(
												$event.target.value,
											)
										"
										inputmode="decimal"
										type="number"
										step="0.01"
									></ion-input>
								</ion-item>
							</ion-col>
							<ion-col>
								<!-- Bed Count -->
								<ion-item lines="none">
									<ion-label position="stacked">{{
										$t("planting.bed-count")
									}}</ion-label>
									<ion-input
										:placeholder="$t('planting.bed-count')"
										:value="bedCountValue"
										@ionChange="
											onChangeBedCount(
												parseInt($event.target.value),
											)
										"
										type="number"
										inputmode="numeric"
										min="0"
										step="1"
									></ion-input>
								</ion-item>
							</ion-col>
						</ion-row>
					</ion-grid>
				</template>

				<!-- Weed Field Options -->
				<template v-if="taskTypeValue === TaskTypes.Weeding">
					<ion-list-header class="section-header">{{
						$t("task.type.weeding")
					}}</ion-list-header>
					<ion-grid>
						<ion-row>
							<ion-col>
								<!-- Weed Types -->
								<ion-item lines="none">
									<ion-label position="stacked">{{
										$tc("task.weeding.weed-type", 2)
									}}</ion-label>
									<ion-select
										name="weedTypeSelect"
										@ionChange="
											onChangeWeedTypes(
												strToIntList(
													$event.target.value,
												),
											)
										"
										:disabled="
											!weedTypesById ||
											!Object.keys(weedTypesById).length
										"
										:placeholder="
											!weedTypesById ||
											!Object.keys(weedTypesById).length
												? $t(
														'task.weeding.no-weed-types',
													)
												: $t(
														'task.weeding.select-weed-types',
													)
										"
										multiple
									>
										<ion-select-option
											v-for="(type, id) in weedTypesById"
											:key="id"
											:value="id"
											>{{ type.name }}</ion-select-option
										>
									</ion-select>
								</ion-item>
							</ion-col>
						</ion-row>
						<ion-row>
							<ion-col>
								<!-- Large Weeds -->
								<ion-item
									lines="none"
									style="
										font-size: 14px;
										--padding-start: 6px;
									"
								>
									<ion-label
										class="label-width-fix"
										position="fixed"
										>{{
											$t("task.weeding.large-weeds")
										}}</ion-label
									>
									<ion-checkbox
										name="largeWeedsCheckbox"
										slot="start"
										:value="largeWeedsValue"
										@ionChange="
											onChangeLargeWeeds(
												$event.target.checked,
											)
										"
									></ion-checkbox>
								</ion-item>
							</ion-col>
							<ion-col>
								<!-- Weed All -->
								<ion-item
									lines="none"
									style="
										font-size: 14px;
										--padding-start: 6px;
									"
								>
									<ion-label
										class="label-width-fix"
										position="fixed"
										>{{
											$t("task.weeding.weed-all")
										}}</ion-label
									>
									<ion-checkbox
										name="weedAllCheckbox"
										slot="start"
										:value="weedAllValue"
										@ionChange="
											onChangeWeedAll(
												$event.target.checked,
											)
										"
									></ion-checkbox>
								</ion-item>
							</ion-col>
						</ion-row>
						<ion-row>
							<ion-col>
								<!-- Weed Pressure -->
								<ion-item lines="none">
									<ion-label position="stacked">{{
										$t("task.weeding.weed-pressure")
									}}</ion-label>
									<ion-select
										name="weedPressureSelect"
										:value="weedPressureValue"
										interface="popover"
										@ionChange="
											onChangeWeedPressure(
												parseInt($event.target.value),
											)
										"
									>
										<ion-select-option
											v-for="(id, name) in WeedPressure"
											:key="id"
											:value="id"
											>{{ name }}</ion-select-option
										>
									</ion-select>
								</ion-item>
							</ion-col>
							<ion-col>
								<!-- Job Tool -->
								<ion-item lines="none">
									<ion-label position="stacked">{{
										$t("task.job-tool")
									}}</ion-label>
									<ion-select
										name="jobToolSelect"
										:value="jobToolValue"
										interface="popover"
										@ionChange="
											onChangeJobTool(
												parseInt($event.target.value),
											)
										"
									>
										<ion-select-option
											v-for="(id, name) in WeedingJobTool"
											:key="id"
											:value="id"
											>{{ name }}</ion-select-option
										>
									</ion-select>
								</ion-item>
							</ion-col>
						</ion-row>
						<ion-row>
							<ion-col>
								<!-- Observations -->
								<ion-item lines="none">
									<ion-label position="stacked">{{
										$tc("common.observation", 2)
									}}</ion-label>
									<ion-select
										name="observationsSelect"
										@ionChange="
											onChangeObservations(
												strToIntList(
													$event.target.value,
												),
											)
										"
										:placeholder="
											$t('common.select-x', {
												x: $tc('common.observation', 2),
											})
										"
										multiple
									>
										<ion-select-option
											v-for="(
												id, name
											) in TaskRequestObservation"
											:key="id"
											:value="id"
											>{{ name }}</ion-select-option
										>
									</ion-select>
								</ion-item>
							</ion-col>
						</ion-row>
						<!-- Hidden for now... -->
						<ion-row v-if="false">
							<ion-col>
								<!-- Observation Comments -->
								<ion-item lines="none">
									<ion-label position="stacked">{{
										$t("task.weeding.observation-comments")
									}}</ion-label>
									<ion-textarea
										class="textarea-blob"
										autogrow
										name="observationComments"
										:value="observationCommentsValue"
										@ionChange="
											onChangeObservationComments(
												$event.target.value,
											)
										"
									></ion-textarea>
								</ion-item>
							</ion-col>
						</ion-row>
					</ion-grid>
				</template>
			</ion-list>

			<ion-button
				@click="submit"
				:disabled="!canSubmit"
				expand="block"
				style="margin-top: 16px"
			>
				{{ $t("task.requests.submit-request") }}
			</ion-button>
		</ion-content>
	</ion-page>
</template>

<style>
	#planting-lot-select-button {
		border-radius: 8px;
		--background: #eee;
		--padding-bottom: 2px;
		--padding-top: 2px;
		--padding-start: 12px;
	}

	.info-button {
		height: 34px;
		font-size: 14px;
		margin-right: 0;
		--padding-start: 6px;
		--padding-end: 11px;
	}

	#task-request-form ion-col > ion-item.item-lines-none {
		--padding-start: 0px;
		--inner-padding-start: 0px;
	}

	#task-request-form ion-input,
	#task-request-form ion-select,
	#task-request-form .date-input {
		background: #eee;
		border-radius: 7px;
		margin-top: 2px;
	}

	#task-request-form ion-input {
		padding: 4px 7px !important;
	}

	#task-request-form ion-select {
		padding: 12px 7px !important;
	}

	#task-request-form .date-input {
		padding: 12px 10px !important;
	}

	/* Ionic picker bug fix: (fixed in v5.1.0, remove when upgraded) */
	ion-picker {
		touch-action: none;
	}

	/* Ugh Ionic */
	ion-label.label-fixed.label-width-fix {
		min-width: 0px; /* remove arbitrary 100px width constraints */
		width: auto;
	}

	.list-ios-lines-full .item {
		--padding-start: 8px;
	}

	/* Text inputs */

	.textarea-blob {
		background-color: #eee;
		border-radius: 7px;
		padding: 8px;
		margin-top: 4px;
	}

	.text-blob {
		background-color: #eee !important;
		border-radius: 7px !important;
		padding: 4px 7px !important;
		margin-top: 4px !important;
	}

	.section-header {
		margin-top: 16px;
		font-size: 1em;
	}

	/* Collapsable info panel */
	.collapsable-panel {
		max-height: 0px;
		overflow: hidden;
		visibility: visible;
		transition: ease-in 0.2s max-height;
	}

	.collapsable-panel.expanded {
		max-height: 265px;
	}

	.add-edit-task-request {
		--height: 100%;
	}
</style>

<script>
	import { mapState } from "vuex";
	import moment from "moment";

	import {
		dateFormat,
		getCleansedDate,
		getLotUIDFromPlanting,
		capitalizeFirstLetter,
	} from "@/utils";
	import {
		TaskTypes,
		WeedingJobTool,
		WeedPressure,
		TaskRequestObservation,
	} from "@/constants";

	import taskRequestApi from "@/api/task-request";
	import { defineAsyncComponent } from "vue";
	import {
		IonTitle,
		IonButton,
		IonToolbar,
		IonHeader,
		IonIcon,
		IonLabel,
		IonItem,
		IonList,
		IonCol,
		IonRow,
		IonGrid,
		IonTextarea,
		IonSelectOption,
		IonSelect,
		IonContent,
		IonPage,
		IonText,
		IonInput,
		IonListHeader,
		IonCheckbox,
		modalController,
	} from "@ionic/vue";
	import {
		map as mapicon,
		informationCircleOutline as informationcircleoutlineicon,
	} from "ionicons/icons";

	import { editDate } from "@/views/Modal/Date.vue";
	import { showSelectLocationsMap } from "@/views/Modal/SelectLocationsMap.vue";

	export default {
		name: "AddEditTaskRequest",
		components: {
			IonTitle,
			IonButton,
			IonToolbar,
			IonHeader,
			IonIcon,
			IonLabel,
			IonItem,
			IonList,
			IonCol,
			IonRow,
			IonGrid,
			IonTextarea,
			IonSelectOption,
			IonSelect,
			IonContent,
			IonPage,
			IonText,
			IonInput,
			IonListHeader,
			IonCheckbox,
		},
		props: {
			original: { type: Object },
			parent: { type: Object },
			incomingPlanting: { type: Object },
		},
		data() {
			return {
				mapicon,
				informationcircleoutlineicon,

				TaskTypes,
				WeedingJobTool,
				WeedPressure,
				TaskRequestObservation,

				infoCollapsed: true,
				generalTaskTitleValue: "", // Holds the last typed general task title

				/**
				 * "v-model" bound form values
				 *   (can't actually use v-model until we upgrade @ionic-vue)
				 */
				plantingsValue: new Set(), // Selected plantings
				lotsValue: new Set(), // Selected lots
				selectedLocationType: "planting", // The type of location ['planting', 'lot'] selected
				...this.getDefaultFields(),
			};
		},
		created() {
			if (this.incomingPlanting) {
				const harvestDate = moment(
					getCleansedDate(
						this.incomingPlanting.currentHarvestDateRaw,
					),
				);

				// Check if the harvest date is before today, if so select the lot instead since the planting is over
				if (harvestDate.isBefore(moment(), "day"))
					this.selectLot(
						getLotUIDFromPlanting(this.incomingPlanting),
					);
				else this.selectPlanting(this.incomingPlanting.id);

				this.tryFillFields();
			}
		},
		methods: {
			dismiss() {
				modalController.dismiss(this);
			},
			/* Get the default state for form-fillable fields of this page */
			getDefaultFields() {
				return {
					taskDateValue: new Date(), // Date for this task, default to today
					taskTypeValue: TaskTypes.General, // Id of this task type
					commodityValue: null, // Id of this task's commodity, if any
					acresValue: null,
					titleValue: "",
					commentsValue: "", // Task description/comments

					// Thin Field values
					plantSpacingValue: null,
					bedCountValue: null,

					// Weed Field values
					weedTypeValues: [], // Array of weed type ids
					largeWeedsValue: false, // Large weeds checkbox
					jobToolValue: 0, // Id of job tool
					weedPressureValue: 0, // Id of weed pressure type
					weedAllValue: false, // Should we weed all (checkbox)
					observationsValues: [], // List of observations for task
					observationCommentsValue: "", // Comments on observations
				};
			},

			pickDate() {
				// Need to use midnight instead of current time. Otherwise, the date input will show up blank for the current date.
				let today = new Date();
				today.setHours(0, 0, 0, 0);
				editDate({
					required: true,
					callback: (date) =>
						this.onChangeTaskDate(new Date(date + "T12:00:00")),
					value: this.taskDateValue,
					title: this.$t("task.task-date"),
					minDate: today, // Don't allow selecting before today
					maxDate: getCleansedDate(
						this.currentPlanting?.currentHarvestDateRaw,
					),
				});
			},
			showSelectLocationsMap() {
				showSelectLocationsMap(this.onChangeSelectedLocations, {
					title: this.$t("location.select-location"),
					selectTypes: ["planting", "lot"],
					selected: {
						type: this.selectedLocationType,
						selection:
							this.selectedLocationType === "planting"
								? this.plantingsValue
								: this.lotsValue,
					},
				});
			},
			onChangeSelectedLocations(data) {
				// Clear task date, as it might be invalid now
				this.taskDateValue = new Date();

				if (data?.type === "planting")
					this.plantingsValue = data.selection ?? new Set();
				else if (data?.type === "lot")
					this.lotsValue = data.selection ?? new Set();

				this.selectedLocationType = data?.type ?? "planting";

				this.tryFillFields();
			},

			/* Data Field Updaters: */

			onChangeTaskDate(newValue) {
				this.taskDateValue = newValue;
			},
			onChangeTitle(newValue) {
				if (this.taskTypeValue === TaskTypes.General)
					this.generalTaskTitleValue = newValue;
				this.titleValue = newValue;
			},
			onChangeComments(newValue) {
				this.commentsValue = newValue;
			},
			onChangeTaskType(newType) {
				if (isNaN(newType)) newType = null;
				this.taskTypeValue = newType;
				this.tryUpdateTitle();
			},
			onChangePlantSpacing(newValue) {
				// Strange logic needed to handle bug with ion-input and "decimal" input mode
				// present in Ionic 4. Ionic returns "" when a decimal ion-input has a "."
				// in it (lol), so we need to ignore empty values

				// Note that this means that "0." or "7." are not valid inputs, because we
				// can't tell the difference
				if (newValue !== "")
					this.plantSpacingValue = this.parseFloatOrNull(newValue);
				else this.plantSpacingValue = null;
			},
			onChangeBedCount(newCount) {
				if (isNaN(newCount)) newCount = null;
				this.bedCountValue = newCount;
			},
			onChangeWeedTypes(newValue) {
				this.weedTypeValues = newValue;
			},
			onChangeLargeWeeds(newValue) {
				this.largeWeedsValue = newValue;
			},
			onChangeJobTool(newValue) {
				if (isNaN(newValue)) newValue = null;
				this.jobToolValue = newValue;
			},
			onChangeWeedPressure(newValue) {
				if (isNaN(newValue)) newValue = null;
				this.weedPressureValue = newValue;
			},
			onChangeWeedAll(newValue) {
				this.weedAllValue = newValue;
			},
			onChangeObservations(newValue) {
				this.observationsValues = newValue;
			},
			onChangeObservationComments(newValue) {
				this.observationCommentsValue = newValue;
			},

			/* Helper Functions */

			strToIntList(list) {
				return list.map((x) => +x);
			},
			/**
			 * Get a planting object by its id
			 * @param {int} plantingId
			 */
			getPlantingById(plantingId) {
				return this.plantings.find((p) => p.id == plantingId);
			},
			/**
			 * Get a planting's display name
			 * @param {Planting} planting
			 */
			getPlantingName(planting) {
				return [
					planting.environmentalRegionName,
					planting.plantingRegion,
				].join(" ");
			},

			/* Autofill Methods */

			/** Try to fill fields with the selected locations' data */
			tryFillFields() {
				// Clear the task type select
				this.taskTypeValue = TaskTypes.General;

				// If plantings, fill with plantings data
				if (
					this.locationType == "planting" &&
					this.plantingsValue.size
				) {
					this.fillWithPlantingsData(this.selectedPlantings);
				}
				// If lots, try to find matching plantings
				else if (this.locationType == "lot" && this.lotsValue.size) {
					let matchingPlantings = this.selectedLots.reduce(
						(arr, lot) => {
							const candidatePlantings = this.plantings.filter(
								(p) => getLotUIDFromPlanting(p) === lot.uid,
							);

							const newestMatchingPlanting = candidatePlantings
								.sort((a, b) => {
									return Date.parse(a.wetDate) <
										Date.parse(b.wetDate)
										? -1
										: 1;
								})
								.find(() => true); // first

							if (newestMatchingPlanting)
								arr.push(newestMatchingPlanting);

							return arr;
						},
						[],
					);

					// Fill with matched planting data
					if (matchingPlantings.length)
						this.fillWithPlantingsData(matchingPlantings);
					else this.clearPlantingDataFields();
				} else this.clearPlantingDataFields();
			},
			/** Fill the form data with given planting data */
			fillWithPlantingsData(plantings) {
				if (
					plantings.every(
						(p) =>
							p.currentPlantSpacing ===
							plantings[0].currentPlantSpacing,
					)
				)
					this.plantSpacingValue = plantings[0].currentPlantSpacing;
				if (
					plantings.every(
						(p) =>
							p.currentBedCount === plantings[0].currentBedCount,
					)
				)
					this.bedCountValue = plantings[0].currentBedCount;
			},
			/** Clear the planting-specific fields */
			clearPlantingDataFields() {
				this.plantSpacingValue = null;
				this.bedCountValue = null;
			},
			/* Try to fill the title field with a reasonable default if possible */
			tryUpdateTitle() {
				const TypeTitleMap = {
					[TaskTypes.General]: this.generalTaskTitleValue,
					[TaskTypes.Thinning]: this.$t("task.type.thinning"),
					[TaskTypes.Weeding]: this.$t("task.type.weeding"),
				};

				this.titleValue = TypeTitleMap[this.taskTypeValue];
			},
			isNullOrWhitespace(str) {
				return !str || !str.trim();
			},

			/** Submit this Task Request */
			submit() {
				const locations = {
					planting: this.selectedPlantings.map((p) => p.id),
					lot: this.selectedLots.map((l) => l.uid),
				}[this.locationType];

				if (!locations?.length) return;

				const locationKey = {
					planting: "plantingId",
					lot: "lotId",
				}[this.locationType];

				const taskRequests = locations.map((locationId) => {
					// Set up the base info for every task request
					const taskRequest = {
						requestType: this.taskTypeValue,
						requestDate: dateFormat(
							this.taskDateValue,
							"yyyy-MM-dd",
							false,
						),

						[locationKey]: locationId,

						commodityId: this.commodityValue,
						acres: this.acresValue,
						title: this.titleValue.trim(),
						requestComments: this.commentsValue,
					};

					// Fill in sub-type data
					switch (taskRequest.requestType) {
						case TaskTypes.Thinning:
							taskRequest.plantSpacing = this.plantSpacingValue;
							taskRequest.bedCount = this.bedCountValue;
							break;

						case TaskTypes.Weeding:
							taskRequest.weedTypes = this.weedTypeValues.map(
								(id) => this.weedTypesById[id],
							);
							taskRequest.largeWeeds = this.largeWeedsValue;
							taskRequest.tool = this.jobToolValue;
							taskRequest.weedPressure = this.weedPressureValue;
							taskRequest.weedAll = this.weedAllValue;
							taskRequest.observations = this.observationsValues;
							taskRequest.observationComments =
								this.observationCommentsValue;
							break;
					}

					return taskRequest;
				});

				// TODO: fill in old info if editing
				taskRequestApi.createOrUpdate(taskRequests);

				// Close modal
				modalController.dismiss(this);
			},
			dateString: function (date) {
				if (typeof date === "string") date = getCleansedDate(date);
				if (!date || !(date instanceof Date)) return;

				return date.toLocaleDateString(this.locale, {
					weekday: "short",
					month: "short",
					day: "numeric",
					year: "numeric",
				});
			},
			capitalizeFirstLetter,
			parseFloatOrNull(value) {
				const val = parseFloat(value);
				return isNaN(val) ? null : val;
			},
		},
		computed: {
			...mapState([
				"online",
				"user",
				"customerCommodityAliases",
				"lotViewsById",
				"weedTypesById",
				"locale",
			]),
			...mapState({
				plantings: "regions",
			}),
			/** Are we editing a task request or creating a new one? (note: edit not implemented yet...) */
			editing() {
				// Is there an original task request to work with?
				return !!this.original;
			},
			/** List of commodities present in the loaded plantings */
			commodities() {
				const commodityIds = new Set(
					this.plantings.map(
						(planting) => planting.plantedCrop.commodityId,
					),
				);

				const aliasResults = Array.from(commodityIds).map(
					(commodityId) => ({
						commodityId,
						alias: this.customerCommodityAliases[commodityId],
					}),
				);

				return aliasResults.sort((a, b) =>
					a.alias.localeCompare(b.alias),
				);
			},
			/** Determines the current location type (planting, lot) based off of selectedLocationType
			 * from the planting select modal and also the list lengths */
			locationType() {
				if (
					this.selectedLocationType === "planting" &&
					this.plantingsValue.size
				)
					return "planting";
				else if (
					this.selectedLocationType === "lot" &&
					this.lotsValue.size
				)
					return "lot";
				return null;
			},
			/** Name of the planting or lot that is selected (based on locationType) */
			fieldNames() {
				// Check what type of field we've selected
				switch (this.locationType) {
					case "planting":
						return this.selectedPlantings
							.map((p) => p.plantingRegion)
							.join(", ");
					case "lot":
						return (
							"Lots: " +
							this.selectedLots.map((l) => l.fullName).join(", ")
						);
					default:
						return null;
				}
			},
			/** Can we submit this task request */
			canSubmit() {
				let hasLocation =
					this.selectedPlantings.length || this.selectedLots.length;
				let hasTaskDate = this.taskDateValue != null;
				let hasTitle = !this.isNullOrWhitespace(this.titleValue);

				return hasLocation && hasTaskDate && hasTitle;
			},
			/** Can we schedule a thinning task for the selected plantings? */
			canScheduleThin() {
				if (this.locationType === "lot") return false;

				if (this.selectedPlantings.length) {
					return !this.selectedPlantings.some(
						(p) => p.plantMethod === "transplant",
					);
				}

				return true;
			},
			canScheduleWeeding() {
				if (this.locationType === "lot") return false;

				/* Placeholder for (future) more business logic */

				return true;
			},
			/** Currently selected plantings */
			selectedPlantings() {
				return [...this.plantingsValue].map((pid) =>
					this.getPlantingById(pid),
				);
			},
			selectedLots() {
				return [...this.lotsValue].map((uid) => this.lotViewsById[uid]);
			},
			remainingTitleChars() {
				return 45 - this.titleValue.length;
			},
			infoExpandedClass() {
				return this.infoCollapsed ? "" : "expanded";
			},
			showPlantingInfoButton() {
				return (
					this.locationType === "planting" &&
					this.selectedPlantings.length
				);
			},
		},
	};

	export function showAddEditTaskRequest(
		parent,
		originalTaskRequest,
		planting = null,
	) {
		modalController
			.create({
				cssClass: "add-edit-task-request",
				component: defineAsyncComponent(
					() => import("@/views/Modal/AddEditTaskRequest.vue"),
				),
				componentProps: {
					parent: parent,
					propsData: {
						original: originalTaskRequest, // TODO: Edit ability
						incomingPlanting: planting, // Planting to select by default
					},
				},
			})
			.then((m) => m.present());
	}
</script>
